import Link from "next/link";
import React from "react";
import { SupportBanners } from "@/components/base/ui/SupportBanners";

export const Footer = () => {
  const links = [
    {
      title: "はじめての方へ",
      slugs: [
        {
          title: "TOP",
          url: "/",
        },
        {
          title: "新規登録",
          url: "/registrations/new",
        },
        {
          title: "ログイン",
          url: "/signin",
        },
        {
          title: "よくあるご質問",
          url: "http://information.s-d-lab.jp/ja/",
        },
        {
          title: "戦略ラボとは",
          url: "/about",
        },
        {
          title: "仕事タイプ診断",
          url: "/jobtypes",
        },
      ],
    },
    {
      title: "運営者情報",
      slugs: [
        {
          title: "プライバシーポリシー",
          url: "/policy",
        },
        {
          title: "利用規約",
          url: "/term",
        },
        {
          title: "運営会社",
          url: "https://s-d-lab.jp/",
        },
        {
          title: "お問い合わせ",
          url: "https://forms.gle/amrqRZXQnUjpby7A7",
        },
      ],
    },
  ];
  return (
    <footer className={"pt-14 pb-10 text-center px-4 bg-gray-100 border-t"}>
      <div className={"max-w-[1440px] m-auto"}>
        <div className="md:flex justify-center mb-14">
          {links.map((link, index) => (
            <div key={index} className={"text-left mx-3 md:mx-7 mb-10 md:mb-0"}>
              <p className={"md:text-xl font-bold mb-4"}>{link.title}</p>
              {link?.slugs.map((slug, slugIndex) => (
                <div key={slugIndex}>
                  {slug.url.includes("http") ? (
                    <a
                      href={slug.url}
                      target={"_blank"}
                      rel="noreferrer"
                      className={
                        "underline leading-loose underline-offset-2 hover:opacity-70"
                      }
                    >
                      {slug.title}
                    </a>
                  ) : (
                    <Link
                      href={slug.url}
                      className={
                        "underline leading-loose underline-offset-2 hover:opacity-70"
                      }
                    >
                      {slug.title}
                    </Link>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <p className={"text-sm text-center"}>©戦略デザインラボ</p>
      </div>
    </footer>
  );
};
