import "tailwindcss/tailwind.css";
import "@/styles/globals.scss";
import "notyf/notyf.min.css";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { DefaultLayout } from "layouts/default";
import { default as NextHead } from "next/head";
import { googleTagManagerId } from "@/utils/gtm";
import GoogleTagManager, {
  GoogleTagManagerId,
} from "@/components/base/GoogleTagManager";
import NextNProgress from "nextjs-progressbar";
import { useSetRecoilState, RecoilRoot } from "recoil";
import { useEffect } from "react";
import { currentUserState } from "@/states/currentUser";
import { fetchCurrentUser } from "@/requests/currentUser";

const AppInit = () => {
  const setCurrentUser = useSetRecoilState(currentUserState);

  useEffect(() => {
    (async function () {
      try {
        const currentUser = await fetchCurrentUser();
        setCurrentUser(currentUser);
      } catch {
        setCurrentUser(null);
      }
    })();
  }, []);

  return null;
};

const App = ({
  Component,
  pageProps,
  err,
}: {
  Component: any;
  pageProps: any;
  err: any;
}) => {
  const Layout = Component.Layout || DefaultLayout;

  return (
    <RecoilRoot>
      <NextHead>
        <GoogleTagManager
          googleTagManagerId={googleTagManagerId as GoogleTagManagerId}
        />
      </NextHead>
      <Layout>
        <NextNProgress
          color="#E9530C"
          startPosition={0.3}
          stopDelayMs={200}
          height={3}
          showOnShallow={true}
        />
        <Component {...pageProps} err={err} />
      </Layout>
      <AppInit />
    </RecoilRoot>
  );
};

export default App;
