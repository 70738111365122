import Link from "next/link";
import { useCurrentUser } from "@/hoc/useCurrentUser";
import { UserIcon } from "@heroicons/react/24/solid";
import {
  ArrowRightIcon,
  ArrowTopRightOnSquareIcon,
  Bars3BottomRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Image from "next/image";

export const LpHeader = () => {
  const { currentUser } = useCurrentUser();
  const [isOpen, setIsOpen] = useState(false);

  const sabNavs = [
    {
      title: "運営会社",
      slug: "https://s-d-lab.jp/",
    },
    {
      title: "お問い合わせ",
      slug: "https://forms.gle/amrqRZXQnUjpby7A7",
    },
    {
      title: "FAQ",
      slug: "http://information.s-d-lab.jp/ja/",
    },
  ];

  const mainNavs = [
    {
      title: "戦略ラボとは",
      slug: "/about",
    },
    {
      title: "料金表",
      slug: "/plans",
    },
    {
      title: "お知らせ&セミナー情報",
      slug: "/news",
    },
    {
      title: "講師紹介",
      slug: "/specialists",
    },
    {
      title: "講座一覧",
      slug: "/tactics",
    },
  ];

  const router = useRouter();
  useEffect(() => {
    setIsOpen(false);
  }, [router]);

  return (
    <>
      <header className={`duration-300 bg-white max-w-[1440px] m-auto`}>
        <div className="relative bg-white">
          <div className="flex">
            <div className="flex flex-1 items-center lg:ml-8 ml-4 mr-12 lg:flex-initial">
              <Link href={"/"}>
                <Image
                  src={"/images/logo.png"}
                  alt={""}
                  width={144}
                  height={44}
                />
              </Link>
            </div>
            <nav
              className={`border-t lg:border-0 absolute lg:relative top-[60px] lg:top-0 left-0 w-full bg-white flex-1 lg:flex flex-col flex-col-reverse z-10 p-5 lg:p-0 ${
                isOpen ? "block" : "hidden lg:block"
              }`}
            >
              <ul className={"lg:flex lg:space-x-10 flex-grow"}>
                {mainNavs.map((nav, index) => (
                  <li
                    key={index}
                    className={"last:border-b-0 border-b lg:border-0"}
                  >
                    <Link
                      href={`${nav.slug}`}
                      className={
                        "flex items-center relative h-full font-bold group hover:text-blue-700 py-4 lg:py-0 lg:text-sm xl:text-base"
                      }
                    >
                      {nav.title}
                      <span
                        className={
                          "w-full lg:h-1.5 h-px bg-blue-700 absolute bottom-0 left-0 group-hover:block hidden"
                        }
                      />
                      <span
                        className={
                          "absolute top-1/2 right-0 translate-y-[-50%] w-4 lg:hidden"
                        }
                      >
                        <ArrowRightIcon className={"w-4"} />
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
              <ul
                className={
                  "lg:flex lg:basis-[40px] justify-end space-y-1 lg:space-y-0"
                }
              >
                <li className={"hidden lg:block"} />
                {sabNavs.map((nav, index) => (
                  <li key={index} className={"lg:w-1/5"}>
                    <a
                      target={"_blank"}
                      rel={"noreferrer"}
                      href={`${nav.slug}`}
                      className={
                        "relative text-sm flex items-center lg:justify-center h-full font-bold bg-gray-100 p-5 lg:p-0"
                      }
                    >
                      {nav.title}
                      <span
                        className={
                          "absolute top-1/2 right-4 translate-y-[-50%] w-4 lg:hidden"
                        }
                      >
                        <ArrowTopRightOnSquareIcon className={"w-4"} />
                      </span>
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
            {currentUser ? (
              <Link
                href={"/app"}
                className={`lg:flex block lg:px-10 px-2 lg:py-10 py-2 items-center bg-blue-700 text-white hover:opacity-60 ease-out duration-100 text-xs lg:text-base h-[60px] lg:h-auto`}
              >
                <UserIcon className={"w-5 lg:mr-1 mx-auto mb-1"} />
                マイページ
              </Link>
            ) : (
              <>
                <Link
                  href={"/plans"}
                  className={
                    "flex items-center lg:px-10 px-4 bg-[#e9530c] text-white font-bold text-sm lg:text-base hover:opacity-60 ease-out duration-100"
                  }
                >
                  無料
                  <br className={"lg:hidden"} />
                  登録
                </Link>
                <Link
                  href={"/signin"}
                  className={`lg:flex block lg:px-10 px-2 lg:py-10 py-2 items-center bg-blue-700 text-white hover:opacity-60 ease-out duration-100 text-xs lg:text-base h-[60px] lg:h-auto`}
                >
                  <UserIcon className={"w-5 lg:mr-1 mx-auto mb-1"} />
                  ログイン
                </Link>
              </>
            )}
            <div
              className={"flex justify-center items-center px-4 lg:hidden"}
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? (
                <XMarkIcon className={"w-5"} />
              ) : (
                <Bars3BottomRightIcon className={"w-5"} />
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
