import {
  AxiosInstance,
  AxiosResponse,
  AxiosResponseHeaders,
  RawAxiosResponseHeaders,
} from "axios";
import { SignInInput, Headers, ConfirmationInput } from "@/types/auth";
import { Data, User } from "@/types/user";
import { authHeaders } from "@/hoc/authHeaders";

export interface AuthRepository {
  signIn(input: SignInInput): Promise<RawAxiosResponseHeaders>;
  getMe(): Promise<User>;
  signOut(): Promise<void>;
  delete(): Promise<void>;
  confirmation(data: ConfirmationInput): Promise<void>;
}

class AuthRepositoryImpl implements AuthRepository {
  private readonly client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  async signIn(input: SignInInput): Promise<RawAxiosResponseHeaders> {
    const response = await this.client
      .post("/api/v1/auth/sign_in", input)
      .catch((error) => {
        throw error.response.status;
      });
    return response.headers;
  }

  async signOut(): Promise<void> {
    const headers = await authHeaders();
    await this.client.delete("/api/v1/auth/sign_out", { headers });
  }

  async delete(): Promise<void> {
    const headers = await authHeaders();
    await this.client.delete("/api/v1/auth", { headers });
  }

  async getMe(): Promise<User> {
    const headers = await authHeaders();
    const { data } = await this.client.get("/api/v1/me", {
      headers,
    });
    return data;
  }

  async confirmation(data: ConfirmationInput): Promise<void> {
    await this.client.post("/api/v1/auth/confirmation", data);
  }
}

export const newAuthRepository = (client: AxiosInstance): AuthRepository => {
  return new AuthRepositoryImpl(client);
};
