import { LpHeader } from "@/components/base/LpHeader";
import { Footer } from "@/components/base/Footer";
import React, { ReactNode } from "react";
import css from "./default.module.scss";

export const DefaultLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div>
      <div className={css.header}>
        <LpHeader />
      </div>
      <div className={css.headerOffset}>{children}</div>
      <div className={css.footer}>
        <Footer />
      </div>
    </div>
  );
};
