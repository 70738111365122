import { client } from "@/api/client";
import { newAuthRepository } from "@/repositories/auth";

export const fetchCurrentUser = async () => {
  try {
    const repository = newAuthRepository(client);
    const currentUser = await repository.getMe();
    return currentUser;
  } catch (e: any) {
    throw new Error(e);
  }
};
